import React from 'react';
import { bool, func } from 'prop-types';

import {
  Text,
  Box,
  Center,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Heading,
} from '@chakra-ui/react';

import txtWording from './locales';
import { string } from 'prop-types';
import { DEFAULT_BUTTON_PROPS } from '@/constants/colors';

function ModalAddress({ onClose, visible, onCopyText, lang }) {
  const name = 'Robert & Cecillia';
  const address = `Alamat : Jalan Road 700, Kelurahan, Kecamatan, Kota.`;

  const renderBank = () => {
    return (
      <Box padding="16px 0">
        <Text textAlign="center" color="secondaryColorText" marginTop="0" fontWeight="bold">
          {name} <br />
        </Text>
        <Text textAlign="center" fontFamily="bodyAlterative" color="black" fontSize="sm">
          {address}
        </Text>
        <Center>
          <Button
            size="sm"
            fontWeight="normal"
            colorScheme="blackAlpha"
            color="btnMainColor"
            variant="outline"
            marginTop="16px"
            onClick={() => onCopyText(`${name} - ${address}`, txtWording.successCopyAddress[lang])}
          >
            {txtWording.modalCopy[lang]}
          </Button>
        </Center>
      </Box>
    );
  };

  return (
    <Modal size="full" placement="bottom" onClose={onClose} isOpen={visible}>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent maxWidth="500px">
        <ModalCloseButton marginTop="12px" {...DEFAULT_BUTTON_PROPS} />
        <Heading
          //borderBottomWidth="1px"
          fontSize="3xl"
          marginTop="26px"
          color="secondaryColorText"
          fontWeight="normal"
          textAlign="center"
        >
          {txtWording.sendGift[lang]}
        </Heading>
        <ModalBody>
          <Text
            align="center"
            fontFamily="bodyAlterative"
            fontSize="sm"
            margin="16px 0"
            color="black"
          >
            We are truly grateful for your kind thoughts regarding gifts, but considering our future living arrangements, 
            we kindly request monetary gifts instead of physical gifts. Your understanding means a lot to us.
          </Text>
          {false && renderBank()}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

ModalAddress.propTypes = {
  lang: string,
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};

ModalAddress.defaultProps = {
  lang: 'id',
};

export default ModalAddress;
